// theme/index.js
import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import { styles } from './styles'

export const myTheme = extendTheme({
    styles,
    colors: {
      "cyan": {
        "50": "#E7F1FD",
        "100": "#BCD8FA",
        "200": "#92C0F7",
        "300": "#67A7F4",
        "400": "#3C8EF1",
        "500": "#1175EE",
        "600": "#0E5EBE",
        "700": "#0A468F",
        "800": "#072F5F",
        "900": "#031730"
      },
      brand: {
        100: "#f7fafc",
        // ...
        900: "#1a202c",
      },
    },
    config: {
      useSystemColorMode: false,
      // initialColorMode: "dark"
    },
  components: {
    Box: {
      variants: {
        'with-shadow': {
          bg: 'red.400',
          boxShadow: '0 0 2px 2px #efdfde',
        },
      }
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'semibold', // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        'with-shadow': {
          bg: 'red.400',
          boxShadow: '0 0 2px 2px #efdfde',
        },
        'orange': {
          bg: 'orange.400',
          // boxShadow: '0 0 2px 2px #efdfde',
        },
        'red': {
          bg: 'red.400',
          // boxShadow: '0 0 2px 2px #efdfde',
        },
        'gray': {
          bg: '#777777',
          // boxShadow: '0 0 2px 2px #efdfde',
        },
        'green': {
          bg: 'green.400',
          // boxShadow: '0 0 2px 2px #efdfde',
        },
        // 5. We can add responsive variants
        'teal': {
          bg: 'teal.500',
          fontSize: 'md',
        },
        'dateButton': {
          bg: 'cyan.500',
          // fontSize: 'md',
        },
        'dateButtonUnselected': {
          bg: 'cyan.200',
          // fontSize: 'md',
        },
        'black': {
          bg: 'black',
          fontSize: 'md',
          // borderColor: 'white'
        },
        'actionButton': {
          bg: '#777777',
          _focus: {
            borderColor: '#fe992c',
            boxShadow: '0 0 0 2px #fe992c',
          },
          // boxShadow: '0 0 2px 2px #fe992c',
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: 'md', // default is md
        variant: 'gray', // default is solid
        colorScheme: 'orange', // default is gray
      },
    },
  },
  })

// export default theme