import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./pages/Header/index";
import Home from "./pages/Home/index";
import CapturePhoto from "./components/CapturePhoto";
import QrcodeReader from "./components/QrcodeReader";
import CaptureOrphanPhoto from "./components/CaptureOrphanPhoto";
import Html5QrcodeReader from "./components/Html5QrcodeReader";
import VoucherList from "./components/VoucherList";
import Html5BarcodeReader from "./components/Html5BarcodeReader";
import CheckInFromList from "./components/CheckInFromList";
import *  as CONFIG from "./config"
import {
  Box
} from '@chakra-ui/react';
import { setApikey, setCheckuser, setuserData } from "./redux/actions/appActions";
import { getApikey, loadUser } from './utils/SessionToken';
import Footer from './components/Footer';
import { getUiVersion } from './utils/ApiUtilities';
import { compare } from 'compare-versions';
import { setUiVersion } from './redux/actions/appActions';



function App() {

  const { apiKey, uiVersion } = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const getApiKeyFromStorage = React.useCallback(
    async () => {
      // setLoading(true);
      // localStorage.nameuser = undefined;
      // const { apiKey } = await getApikey()
      // setApiKey(apiKey);
      dispatch(setCheckuser(true));
      dispatch(setUiVersion(await getUiVersion()));
      dispatch(setApikey(await getApikey()));
      // console.log('home getNameuser')
      // const nameUser = await getNameuser();
      // setNameUser(nameUser);
      // const tempUserData = await checkUser();
      // console.log(tempUserData);
      dispatch(setuserData(await loadUser()));
      // dispatch(setAccount(await getNameuser()));

      dispatch(setCheckuser(false));
      // const uiVersion = await getUiVersion();
      // if (compare(uiVersion, CONFIG.APP_VERSION, '>')) {
      //   setTimeout(() => {
      //     window.location.reload(true);
      //     // window.location.href = window.location.protocol + '//' + window.location.host;
      //   }, 1000);
      // }
      // console.log('compare version', compare(uiVersion, CONFIG.APP_VERSION, '>'));
      // setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (!apiKey) {
      getApiKeyFromStorage();
    }
  }, [getApiKeyFromStorage, apiKey]);

  useEffect(() => {
    // console.log('uiVersion', uiVersion);
    if (uiVersion === '') return;
    if (compare(uiVersion, CONFIG.APP_VERSION, '>')) {
      dispatch(setCheckuser(true));
      setTimeout(() => {
        window.location.reload(true);
        // dispatch(setCheckuser(false));
        // window.location.href = window.location.protocol + '//' + window.location.host;
      }, 1000);
    }
  }, [uiVersion]);


  return (
    <>
      <Router>
        <Header />
        {/* <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 1, md: 1 }} textAlign="center" > */}
        <Box textAlign="center" >
        <Switch>
            <Route path={CONFIG.HOME_URL} exact component={Home} />
            <Route path={CONFIG.VOUCHER_ORPHAN_PHOTO} exact component={CaptureOrphanPhoto} />
            <Route path={CONFIG.VOUCHER_PHOTO} exact component={CapturePhoto} />
            <Route path={CONFIG.QRCODE_LOGIN } exact component={QrcodeReader} />
            {/* <Route path="/barcode" exact component={BarcodeReader} /> */}
            <Route path={CONFIG.VOUCHER_SCAN} exact component={Html5QrcodeReader} />
            <Route path={CONFIG.VOUCHER_SCAN_BAR} exact component={Html5BarcodeReader} />
            <Route path={CONFIG.VOUCHER_LIST} exact component={VoucherList} />
            {/* <Route path={CONFIG.VOUCHER_CHECK_IN_FROM_LIST} exact component={CheckInFromList} /> */}
            <Route path={CONFIG.VOUCHER_CHECK_IN_FROM_LIST} render={(props) => <CheckInFromList {...props} key={Math.random()} />} />
            
          {/* <Route path="/create-nft" component={CreateNFT} /> */}
          {/* <Route path="/nft/:nftId" component={Item} /> */}
          <Route>404 Not Found!</Route>
        </Switch>
        </Box>
      </Router>

        <Footer />
    </>
  );
}

export default App;
