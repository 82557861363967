
// https://github.com/scanapp-org/html5-qrcode-react/tree/main/src

// import {
//   useState,
//   // useEffect 
// } from "react";
// import Scanner from "react-webcam-qr-scanner";
// import Webcam from "react-webcam";
// import { useHistory } from "react-router-dom";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
// import * as CONFIG from "../config";
// import { checkVoucher } from "../utils/ApiUtilities";
// import { getVouchercode } from "../utils/SessionToken";
// import Loading from "../utils/Loading";


import {
  // ChakraProvider,
  Box,
  Center,
  VStack,
  // Text,
} from '@chakra-ui/react';
// import { useHistory } from "react-router-dom";
// import { ButtonBox } from "./HomeButton";
// import { FaCamera } from "react-icons/fa";
// import { VOUCHER_ORPHAN_PHOTO } from "../config";

function QrcodeReader(props) {
  localStorage.apiKey = '';
  // const history = useHistory();
  const handleDecode = async (decodedText, decodedResult) => {
    // console.log(decodedText);
    // console.log('decodedText', getParameterByName('apiKey', decodedText))
    // localStorage.apiKey = getParameterByName('apiKey', decodedText);
    // history.push(CONFIG.HOME_URL);
    window.location.href = decodeURIComponent(decodedText);
  }

  // const getParameterByName = (name, url) => {
  //   name = name.replace(/[\[\]]/g, '\\$&');
  //   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //     results = regex.exec(url);
  //   if (!results) return url;
  //   if (!results[2]) return '';
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
  // }

  return (
    <>
      <VStack>
        <Box border={'0px'} width={'100%'}>
          <Center>
          <Html5QrcodePlugin
            // fps={60}
            qrbox={props.qrbox}
            disableFlip={true}
            qrCodeSuccessCallback={handleDecode}
            onlybarcode={props.onlybarcode}
          // aspectRatio='1.777778'
          />
          </Center>
        </Box>
        {/* <ButtonBox title={'Check-in con foto'} icon={<FaCamera size={'2em'} />} link={VOUCHER_ORPHAN_PHOTO} /> */}
      </VStack>
    </>
  );

}

export default QrcodeReader;
