import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from "./redux/store";
import { Provider } from "react-redux";
import { ChakraProvider } from '@chakra-ui/react';
import { myTheme } from './theme'
// import Footer from './components/Footer'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ChakraProvider theme={myTheme}>
      <App />
    </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);