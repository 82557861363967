
import { useState } from "react";
import {
    Box,
    Text,
    Tr,
    Td,
    Heading,
    Flex,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';
import { FaCloudDownloadAlt, FaDownload, FaRegEye, FaRegFilePdf } from "react-icons/fa";

function VoucherTicketList(props) {
    const { tickets, viewPdf } = props;
    const ticketTimeText = useColorModeValue('blackAlpha.800', 'whiteAlpha.800');
    const [isActive, setIsActive] = useState({});
    const toggleActive = (idx) =>
        setIsActive((prev) => {
            let status = {};
            // console.log('toggleActive', idx)
            if (prev[idx]) status =  { ...prev, [idx]: false };
            status = { ...prev, [idx]: true };
            // console.log('toggleActive status', status)
            return status;
        });

    // const viewPdf = async (ticketPdf) => {
    //     const pdfUrl = await getTicketPdfUrl(ticketPdf)
    //     console.log("Apro " + pdfUrl);
    //     window.open(pdfUrl, "_blank");
    //     // setPdfFileUrl(pdfUrl);
    //     // onTicketOpen();
    // }

    return (
        <>
            {tickets &&
                <Tr>
                    <Td colSpan={'2'} textAlign={'left'} fontSize={{ base: '16px', md: '22px' }} fontWeight={'bold'} lineHeight={{ base: '22px', md: '26px' }}>Biglietti ingresso</Td>
                </Tr>
            }
            {tickets && Object.keys(tickets).map((id, ticket) => {
                // { console.log('isActive.id', 'id='+id+' - '.isActive[id]) }
                // { console.log(tickets[id]['ticket']) }
                // { const bgColor = voucher.checked ? hoverCheckedColor : hoverUnCheckedColor }
                return (
                    <Tr
                        // onClick={() => { toggleActive(id); viewPdf(Buffer.from(tickets[id]['pdf_filename']).toString('base64')) }} 
                        // onClick={() => { toggleActive(id); viewPdf(tickets[id]['pdf_filename']) }}
                        onClick={() => { toggleActive(id); viewPdf(tickets[id]['pdf_enc_filename']) }}
                        bg={isActive[id] && 'gray.100' }
                        key={'ticket-'+id}
                        _hover={{ bg: "gray.200" }}
                    >
                        <Td colSpan={'2'} lineHeight={{ base: '14px', md: '22px' }}>
                            <Flex>
                                {/* <HStack spacing={'16px'}> */}
                                <Box>
                                    <Heading fontSize={{ base: '13px', md: '18px' }}>
                                        {tickets[id]['ticket']} {tickets[id]['pax']} pax
                                    </Heading>
                                    <Text fontSize={{ base: '10px', md: '14px' }} color={ticketTimeText}>
                                        {tickets[id]['data_from']} dalle {tickets[id]['hour_from']} alle {tickets[id]['hour_to']}
                                    </Text>
                                </Box>
                                <Spacer />
                                <Box
                                margin={'auto'}
                                >
                                    <Text fontSize={{ base: '8px', md: '10px' }} color={ticketTimeText}>
                                    Mostra
                                    </Text>
                                    <FaRegEye size={'2em'}/>
                                </Box>
                                {/* </HStack> */}
                            </Flex>
                        </Td>
                    </Tr>
                )
            })
            }
        </>
    );

}

export default VoucherTicketList;
