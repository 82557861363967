import { useState, useEffect } from "react";
import { getVoucherDetail } from "../utils/ApiUtilities";
import { getVouchercode } from "../utils/SessionToken";
import Html5QrcodeReader from "./Html5QrcodeReader";
import Loading from "../utils/Loading";
import * as CONFIG from "../config";
import { useHistory } from "react-router-dom";

function CheckInFromList() {
    // console.log('CheckInFromList');
    const history = useHistory();
    // const [scanResult, setScanResult] = useState('');
    // const [aquired, setAquired] = useState(false);
    // const [elaborated, setElaborated] = useState(false);
    const [results, setResults] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleDecode = async () => {
        setLoading(true);
        const { vouchercode } = await getVouchercode();
        // console.log('CheckInFromList handleDecode', vouchercode);
        if (vouchercode !== undefined && vouchercode !== 'undefined') {
            // setAquired(true);
            // await getVouchercode(decodedText);
            // console.log('voucherCode', vouchercode)
            const result = await getVoucherDetail(vouchercode);
            // console.log('result', result);
            setResults(result);
            // setResults(await getVoucherDetail(vouchercode));
            // setElaborated(true);
        } else {
            history.push(CONFIG.HOME_URL);
        }
        setLoading(false);
    }

    useEffect(() => {
        handleDecode();
        // const voucherCode = await getVouchercode();
        // console.log('voucherCode', voucherCode)
        // if (voucherCode) {
        //     handleDecode(voucherCode, null);
        // }
    }, []);

    return !loading ? (
        <Html5QrcodeReader _results={results} _elaborated={true} _aquired={true} />
    ) : (
            <Loading label='Loading...' size='120px' />
        )

}

export default CheckInFromList;
