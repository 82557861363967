
// https://github.com/scanapp-org/html5-qrcode-react/tree/main/src

import { 
  useEffect,
  useState,
  // useSelector
} from "react";
// import Scanner from "react-webcam-qr-scanner";
// import Webcam from "react-webcam";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import { checkVoucher, uploadFileVoucher } from "../utils/ApiUtilities";
import { getCameraPermission, getVouchercode } from "../utils/SessionToken";
import Loading from "../utils/Loading";
import { ButtonBox } from "./HomeButton";
import { FaCamera } from "react-icons/fa";
import *  as CONFIG from "../config";

import {
  // ChakraProvider,
  Box,
  VStack,
  // Button,
  // Tooltip,
  Text,
  Button,
  Img,
  Image,
  // Table,
  // Thead,
  // Tbody,
  // Tfoot,
  // Tr,
  // Th,
  // Td,
  // TableCaption,
  // TableContainer,
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import VoucherResults from "./VoucherResults";
import ActionButtons from "./ActionButton";

function Html5QrcodeReader(props) {
  // console.log('Html5QrcodeReader');
  const { _results, _elaborated, _aquired } = props;
  // const { voucherToValidate } = props;
  const history = useHistory();
  const [scanResult, setScanResult] = useState('');
  const [aquired, setAquired] = useState(_aquired);
  const [elaborated, setElaborated] = useState(_elaborated);
  const [fromScan, setFromscan] = useState(false);
  const [results, setResults] = useState(_results);
  const [scanImage, setScanImage] = useState(null);
  const [cameraPermission, setCameraPermission] = useState(localStorage.HTML5_QRCODE_DATA !== undefined ? JSON.parse(localStorage.HTML5_QRCODE_DATA).hasPermission : false);
  // var scanImage = null;
  // const { nameUser, apiKey } = useSelector((state) => state.user);
  // console.log('Html5QrcodeReader', _aquired)

  const handleDecode = async (decodedText, decodedResult) => {
    // lastImage();
    // console.log(result);
    // console.log('result.data', result.data)
    // console.log('decodedText', decodedText)
    setScanResult(decodedText)
    // // console.log('decodedResult', decodedResult);
    setAquired(true);
    
    // No autoupload image
    // setFromscan(true);
    // // Html5QrcodePlugin.
    // // history.push("/");
    // // const res = await checkVoucherGet(decodedText);
    // // console.log(res.voucher.agency);
    // getVouchercode(decodedText);
    // // setResults(await checkVoucherGet(decodedText));
    // setResults(await checkVoucher(decodedText));
    // setElaborated(true);
    // // console.log('Html5QrcodeReader scanImage', scanImage);
    // // console.log('results', results);
  }

  // const lastImage = () => {
  //   const canvas = document.getElementById('canvas');
  //   console.log('canvas', canvas);
  //   const video = document.getElementById('video');
  //   console.log('video', video);
  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;
  //   canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight); // for drawing the video element on the canvas
  //   /** Code to merge image **/
  //   const playImage = new Image();
  //   playImage.src = 'path to image asset';
  //   playImage.onload = () => {
  //     const startX = (video.videoWidth / 2) - (playImage.width / 2);
  //     const startY = (video.videoHeight / 2) - (playImage.height / 2);
  //     canvas.getContext('2d').drawImage(playImage, startX, startY, playImage.width, playImage.height);
  //     // canvas.toBlob() = (blob) => {
  //     //   // Canvas element gives a callback to listen to the event after blob is prepared from canvas
  //     //   const img = new Image();
  //     //   img.src = window.URL.createObjectUrl(blob); // window object with static function of URL class that can be used to get URL from blob
  //     // };
  //   };
  // }

  // useEffect(async () => {
  //   console.log('cambiato localstorage', localStorage.HTML5_QRCODE_DATA)
  //   setCameraPermission(await getCameraPermission());
  //   console.log('cameraPermission', cameraPermission)
  // }, [cameraPermission]);

  // const handleScannerLoad = (mode) => {
  //   console.log(mode);
  // }

  const resetScan = (res) => {
    // console.log('resetScan');
    setElaborated(false);
    // setResults(null);
    setAquired(false);
    setScanResult('');
    setScanImage('');
    // setResults(res);
  }


  const setImage = (image) => {
    setScanImage(image);
    // scanImage = image;
    // console.log('setImage scanImage', image);
  }

  useEffect(async () => {
    if (scanResult === '') return;
    if (scanImage === '') return;
    // console.log('useEffect decodedText', scanResult);
    // console.log('useEffect scanImage', scanImage);
    // setAquired(true);
    // setFromscan(true);
    // Html5QrcodePlugin.
    // history.push("/");
    // const res = await checkVoucherGet(decodedText);
    // console.log(res.voucher.agency);
    getVouchercode(scanResult);
    // setResults(await checkVoucherGet(decodedText));
    // setResults(await checkVoucher(scanResult));
    // setResults(await uploadFileVoucher(scanResult));

    // const res = await uploadFileVoucher(scanImage, 1);
    // if (!res.success) localStorage.vouchercode = undefined;

    const res = await checkVoucher(scanResult, 1);
    // console.log('res.voucher.alreadyValidated', res.voucher.alreadyValidated);
    if (!res.success) {
      localStorage.vouchercode = undefined;
    } else {
      !res.voucher.image && uploadFileVoucher(scanImage, 1);
    }

    // console.log('res', res);
    setResults(res);
    // res.success ? updateResults !== undefined && updateResults(res) : setLoading(false);
    // res.success ? setElaborated(true) : resetScan(res);
    setElaborated(true);
    // console.log('Html5QrcodeReader scanImage', scanImage);
  }, [scanImage])

  // useEffect(() => {
  //   console.log('useEffect results', results);
  //   setElaborated(true);
  //   console.log('Html5QrcodeReader scanImage', scanImage);
  // }, [results])

  const updateResults = (results) => {
    // setElaborated(false);
    setResults(results);
    // console.log('Html5QrcodeReader updateResults', results)
    // setElaborated(true);
  }

  return !aquired ? (
    <Box maxW="4xl" mx={'auto'} pt={0} px={{ base: 2, sm: 1, md: 1 }}>
      {/* <Webcam
        audio={false}
        // height={720}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        // width={1280}
        // videoConstraints={videoConstraints}
      />
      <button onClick={capture}>Capture photo</button> */}
      <VStack>
        {/* <InputValueBox chainId={fromChain} title={CONF.getEnsName(fromChain)} /> */}
        <Box border={'0px'} width={'100%'}>
          {/* <Scanner
            className="some-classname"
            onDecode={handleDecode}
            onScannerLoad={handleScannerLoad}
            constraints={{
              audio: false,
              video: {
                facingMode: "environment"
              }
            }}
            // captureSize={{ width: 1280, height: 720 }}
          /> */}
          <Text fontWeight={'bold'} m={'12px'}>
            {
              cameraPermission === true ? 'Inquadra il QRcode per effettuare il Fast Check-in' : 'Abilita la fotocamera quindi inquadra il QRcode per effettuare il Fast Check-in'
            }
            </Text>
          <Html5QrcodePlugin
            // fps={60}
            qrbox={ props.qrbox }
            disableFlip={true}
            qrCodeSuccessCallback={handleDecode}
            onlybarcode={props.onlybarcode}
            setImage={setImage}
            // aspectRatio='1.777778'
            />
        </Box>
        <Box maxW="4xl" mx={'auto'} pt={0} px={{ base: 2, sm: 1, md: 1 }}>
        {/* {nameUser && apiKey ? <ButtonBox title={CONFIG.} icon={<FaCamera size={'2em'} />} link={CONFIG.VOUCHER_ORPHAN_PHOTO} descText={CONFIG.NOTE_ORPHAN} /> : <></>} */}
        {/* <ButtonBox title={CONFIG.BUTTON_ORPHAN} icon={<FaCamera size={'1.3em'} />} link={CONFIG.VOUCHER_ORPHAN_PHOTO} descText={CONFIG.NOTE_ORPHAN} /> */}
          {/* <Button fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.VOUCHER_ORPHAN_PHOTO) }} mr={'5px'}>{CONFIG.BUTTON_ORPHAN}</Button> */}
          {/* <Button fontSize={'22px'} width={'100%'} onClick={handleResetScan}>Nuova scansione</Button> */}
          {/* <Button fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.HOME_URL) }} mr={'5px'}>{'Lista voucher'}</Button> */}
          {/* { cameraPermission === true && */}
            <>
            <ActionButtons onClick={() => { localStorage.vouchercode = undefined; history.push(CONFIG.VOUCHER_ORPHAN_PHOTO) }} mr={'5px'} text={CONFIG.BUTTON_ORPHAN} />
            <ActionButtons onClick={() => { localStorage.vouchercode = undefined; history.push(CONFIG.HOME_URL) }} mr={'5px'} text={CONFIG.BUTTON_BACK_TO_LIST} />
            {/* <Image src={scanImage} /> */}
            </>
          {/* } */}
        </Box>
        {/* <Box>
          <Button onClick={handlePushBarcode}>
            Barcode
          </Button>
          <Button onClick={handlePushQrcode}>
            Qrcode
          </Button>
        </Box> */}
        <Box pb={'200px'}>
          <Text>{scanResult}.</Text>
        </Box>
      </VStack>
    </Box>
  ) : (
      elaborated === true ? <VoucherResults resetScan={resetScan} results={results} elaborated={elaborated} updateResults={updateResults} fromScan={fromScan} scanImage={scanImage} setImage={setImage} /> : <Loading label='Loading...' size='120px' />
        );

}

export default Html5QrcodeReader;
