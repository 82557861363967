
import React from "react";
import {
  // Box,
  // chakra,
  // Flex,
  // SimpleGrid,
  // Stat,
  // StatLabel,
  // StatNumber,
  useColorModeValue,
  // Stack,
  Text,
  // Center,
  // Divider,
  // LinkBox,
  Button,
  Image
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
import { ReactNode } from 'react';
// import { FaDeezer, FaGem, FaFunnelDollar, FaHandHoldingUsd, FaMoneyCheckAlt, FaPiggyBank, FaTicketAlt, FaWallet } from "react-icons/fa";
// import { FaQrcode, FaCamera } from "react-icons/fa";
// import { VOUCHER_SCAN, VOUCHER_PHOTO } from '../config'
// import { userSuccess, availPoint } from "../utils/WalletUtils";
import { useHistory } from "react-router-dom";





interface ButtonBoxProps {
  title: string;
  link: string;
  fontSize: string;
  paddingBottom: string
  // usd_ski_rate: number;
  icon: ReactNode;
  iconHead: ReactNode;
  iconMiddle: ReactNode;
  // showUsd: boolean;
  state: any;
  descText: string;
  forceReload: boolean;
  buttonHeight: string;
  bgColor: string;
}
export function ButtonBox(props: ButtonBoxProps) {
  const history = useHistory();
  const handlePushUrl = (urlpath: string, forceReload: boolean) => {
    // console.log('forceReload', forceReload);
    // forceReload && urlpath !== '' && setTimeout(() => {
    //   // console.log('urlpath', urlpath);
    //   window.location.href = urlpath;
    // }, 1);
    !forceReload && urlpath !== '' && history.push(urlpath);
  }
  const { title, link, icon, fontSize, forceReload, iconHead, iconMiddle, buttonHeight, bgColor } = props;

  // console.log('descText', descText);
  const _canWithdraw = true;
  // const bgGradient = _canWithdraw ? 'linear(to-l, red.500, yellow.500)' : 'linear(to-l, red.400, yellow.400)';
  // const hoverBgGradient = _canWithdraw ? 'linear(to-l, #FF0080, yellow.400)' : 'linear(to-l, #FF0080, yellow.400)';
  // const from = _canWithdraw ? '#FF0080' : 'gray.500';
  // const to = _canWithdraw ? '#FF0080' : 'gray.500';
  // const bgGradient = _canWithdraw ? 'black' : 'black';
  const bg = useColorModeValue('gray', 'gray')
  const hoverBgGradient = _canWithdraw ? 'linear(to-l, orange.400, orange.600)' : 'linear(to-l, gray.800, gray.800)';
  // const bgGradient = useColorModeValue('linear(to-l, gray, gray)', 'linear(to-l, gray., gray)');
  const bgGradient = useColorModeValue('linear(to-b, orange.300, orange.500)', 'linear(to-r, purple, orange)');
  // const bgGradient = useColorModeValue('linear(to-l, white, white)', 'linear(to-l, white, gray.300)');
  // const bgGradient = useColorModeValue('linear(to-l, cyan.500, #FF0080)', 'linear(to-l, cyan.500, #FF0080)');
  // const hoverBgGradient = _canWithdraw ? 'linear(to-l, cyan.500, #FF0080)' : 'linear(to-l, gray.800, gray.800)';
  // const from = _canWithdraw ? 'gray.800' : 'gray.600';
  // const to = _canWithdraw ? 'gray.200' : 'gray.600';
  const color = useColorModeValue('white', 'black');
  // const colorInv = useColorModeValue(from, to);
  // const fSize = fontSize ? fontSize : '2xl';
  // const pBottom = paddingBottom ? paddingBottom : '0px';
  return (
    <Button
      size='md'
      height={buttonHeight !== undefined ? buttonHeight : '48px'}
      width='100%'
      border='0px'
      // borderColor='cyan'
      // ml={'10px'}
      // variant='black'
      
      onClick={() => handlePushUrl(link, forceReload)}
      bg={bg}
      bgGradient={bgGradient}
      _hover={{
        bgGradient: hoverBgGradient,
      }}
      boxShadow='dark-lg'
      color={color}
      whiteSpace={'pre'}
    >
      {/* {iconHead}{iconHead && ' '}{iconMiddle && ' ——> '}{iconMiddle}{iconMiddle && ' '}{iconMiddle && ' <——'}{!iconMiddle && <Text fontSize={fontSize !== undefined ? fontSize : '14px'}>{title}</Text>}{icon && ' '}{icon} */}
      <Image src="/click-scan-1.png" height={'70px'} />
      {/* <Image src="scan-qrcode-white.png" height={'80px'} /> */}
      {/* <Img src="barcode-trasp-min.png" height={'100px'} width={'300px'} /> */}
    </Button>
  );
}

// interface CardProps {
//   // usd_ski_rate: number;
//   // user: any;
//   state: any;
// }

// export default function HomeButton(props: CardProps) {
//   // const { account } = GetAccount();
//   const { state } = props;
//   // console.log('DetailsCards', state.conf.default_network_name);
//   // state.user.withdrawls.map((w: any) => {
//   //   // return <ButtonBox key={index} date={item.checkDate} amount={item.gamepoint.toFixed(2).toString()} ip={item.requestip} transactionHash={item.txid} success={item.success} />
//   //   console.log(w.checkDate)
//   // });
//   // return state.success ? (
//   // return userSuccess(state.user) ? (
//     return (
//     <>
//     <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
//       {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}> */}
//           <ButtonBox title={'Scansiona un Voucher'} icon={<FaQrcode size={'2em'} />} state={state} link={VOUCHER_SCAN} />
//           <ButtonBox title={'Fotografa voucher'} icon={<FaCamera size={'2em'} />} state={state} link={VOUCHER_PHOTO} />
//           {/* <ButtonBox title={'Mostra Voucher di oggi'} icon={<FaThList size={'2em'} />} state={state} link={VOUCHER_PHOTO} /> */}
//       {/* </SimpleGrid> */}
//     </Box>
//     </>
//     )
// }