// import React from 'react';
import React from 'react'
import {
    Box,
    // chakra,
    // Tooltip,
    Text,
    // Image,
    // Spacer,
    // Center,
    // Slide,
    // Button,
    // Wrap,
    // WrapItem,
    // Center,
    SimpleGrid
} from '@chakra-ui/react';
// import Footer from '../../components/Footer';
// import { ColorModeSwitcher } from '../../ColorModeSwitcher';
// import { Logo } from '../../Logo';
// import ConnectButton from '../../components/ConnectButton';
// import { useSelector } from "react-redux";
// import { TransactionsList } from '../../components/SwapHistory';
// import SwapHistory from '../../components/SwapHistory.tsx';
// import { ConfigProvider } from '@usedapp/core';
// import * as CONF from '../../config';
// import { addSKItoken } from '../../utilities/chainUtility'
// import QrcodeReader from '../../components/QrcodeReader'
// import { TITLE } from '../../config'
import { ButtonBox } from '../../components/HomeButton';
// import { ButtonBox } from '../../components/HomeButton';
import { FaQrcode, FaCamera, FaBarcode, FaMobileAlt, FaCameraRetro } from "react-icons/fa";
import * as CONFIG from '../../config'
// import { getApikey, getNameuser } from '../../utils/SessionToken';
import Loading from '../../utils/Loading';
import VoucherList from '../../components/VoucherList';
import { useSelector } from "react-redux";
import { WarningTwoIcon } from '@chakra-ui/icons';
import Html5QrcodeReader from '../../components/Html5QrcodeReader';
import ActionButtons from '../../components/ActionButton';
import { useHistory } from 'react-router-dom';
import QrcodeReader from '../../components/QrcodeReader';

// import Realistic from '../../components/Confetti';
// import {
//     setAccount,
//     setApikey
// } from "../../redux/actions/appActions";



function Home() {
    const history = useHistory();
    // const { apiKey } =  getApikey()

    // const [apiKey, setApiKey] = useState('');
    // const [nameUser, setNameUser] = useState('');
    const { apiKey, checkUser, userData } = useSelector((state) => state.user);
    // const [loading, setLoading] = useState(false);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     if (!apiKey) {
    //         getApiKeyFromStorage();
    //     }
    // }, [apiKey]);


    // const getApiKeyFromStorage = async () => {
    //     setLoading(true);
    //     // localStorage.nameuser = undefined;
    //     // const { apiKey } = await getApikey()
    //     // setApiKey(apiKey);
    //     dispatch(setApikey(await getApikey()));
    //     // console.log('home getNameuser')
    //     // const nameUser = await getNameuser();
    //     // setNameUser(nameUser);
    //     dispatch(setAccount(await getNameuser()));
    //     setLoading(false);
    //     // console.log('apiKey', apiKey)
    // };

    // console.log('---------------------Rendering index.js---------------------')
    // const [state, setstate] = useState({
    //     onSubmit: false,
    // })

    return checkUser ? (
        <Box w={'100%'} h={'100%'}>
            <Loading label='Loading...' size='120px' />
        </Box>
    ) : (
        <>
            {/* <Realistic /> */}
            {/* <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 1, md: 1 }}> */}
            <Box>
                {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}> */}
                    {
                    localStorage.success !== 'false' ?
                        userData !== undefined && apiKey ?
                        <>
                            <SimpleGrid columns={1} spacingX={'4px'} pl={'4px'} pr={'4px'} maxWidth='380px' mx={'auto'} mt='5px'>
                                        {/* <Html5QrcodeReader /> */}
                                        {/* <ButtonBox title={' '} icon={<FaBarcode size={'2em'} />} link={CONFIG.VOUCHER_SCAN_BAR} />
                                        <ButtonBox title={' '} icon={<FaQrcode size={'2em'} />} link={CONFIG.VOUCHER_SCAN} /> */}
                                        {/* <ButtonBox buttonHeight='56px' title={CONFIG.BUTTON_SCAN_HOME} fontSize={'22px'} iconHead={<FaBarcode size={'1.7em'} />} icon={<FaQrcode size={'1.7em'} />} iconMiddle={<FaCamera size={'2.5em'} />} link={CONFIG.VOUCHER_SCAN} /> */}
                                        <ButtonBox buttonHeight='76px' title={CONFIG.BUTTON_SCAN_HOME} fontSize={'20px'} iconHead={<FaBarcode size={'3.7em'} />} icon={<FaQrcode size={'3em'} />} link={CONFIG.VOUCHER_SCAN} iconMiddle={<FaCamera size={'4.5em'} />} />
                                {/* <ButtonBox title={'Foto Voucher'} icon={<FaCamera size={'2em'} />} link={CONFIG.VOUCHER_ORPHAN_PHOTO} /> */}
                                {/* <ButtonBox title={'Scansiona BAR code '} icon={<FaBarcode size={'2em'} />} link={CONFIG.VOUCHER_SCAN_BAR} />
                                    <ButtonBox title={'Scansiona QRcode '} icon={<FaQrcode size={'2em'} />} link={CONFIG.VOUCHER_SCAN} /> */}
                            </SimpleGrid>
                            {/* <ButtonBox title={'Foto Voucher'} icon={<FaCamera size={'2em'} />} link={CONFIG.VOUCHER_ORPHAN_PHOTO} /> */}
                            {/* <ButtonBox title={'Lista voucher'} icon={<FaThList size={'2em'} />} link={CONFIG.VOUCHER_LIST} /> */}
                            {/* <ButtonBox title={'Lista voucher sospesi'} icon={<FaClipboardList size={'2em'} />} link={CONFIG.VOUCHER_LIST} /> */}
                            <VoucherList />
                        </>
                                : <>
                                    <Box
                                        p={'15px'}
                                        m={'15px'}
                                        mt={'40px'}
                                        mb={'100px'}
                                        boxShadow='lg'
                                        rounded={'30'}
                                        bgColor={'gray.300'}
                                    >
                                        {/* <WarningTwoIcon boxSize={'8em'} color={'red.500'} mb={'15px'}></WarningTwoIcon> */}
                                        <Text
                                            fontSize={'1.5em'}
                                            color={'red.800'}
                                            fontWeight={'bold'}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: localStorage.error }} />
                                            <Text>{CONFIG.TEXT_LOGIN_QRCODE}</Text>
                                            <ActionButtons onClick={() => { history.push(CONFIG.QRCODE_LOGIN) }} text={CONFIG.BUTTON_LOGIN_QRCODE} />
                                        </Text>
                                    </Box>
                        </>
                    :
                    <>
                    <Box
                    p={'15px'}
                    m={'15px'}
                    mt={'40px'}
                    mb={'100px'}
                    boxShadow='lg'
                    rounded={'30'}
                    bgColor={'gray.300'}
                    >
                        <WarningTwoIcon boxSize={'2em'} color={'red.500'} mb={'15px'}></WarningTwoIcon>
                        <Text
                        fontSize={'1em'}
                        color={'red.800'}
                        fontWeight={'bold'}
                        >
                                        <div dangerouslySetInnerHTML={{ __html: localStorage.error }} />
                                        {/* {localStorage.error} */}
                                        <Text mt={'15px'}>{CONFIG.TEXT_LOGIN_QRCODE}</Text>
                                        {/* <ButtonBox title={'Scansiona il QrCode'} icon={<FaCamera size={'1.3em'} />} link={CONFIG.QRCODE_LOGIN} /> */}
                                        <ActionButtons onClick={() => { history.push(CONFIG.QRCODE_LOGIN) }} text={CONFIG.BUTTON_LOGIN_QRCODE} />
                        </Text>
                        {/* <QrcodeReader /> */}
                    </Box>
                    </>
                }
            </Box>
            {/* <QrcodeReader /> */}
            {/* <SwapBox /> */}
            {/* {history()} */}
            {/* {account && chainId ? <SwapHistory addressContract={CONF.getBridgeContract(chainId)} currentAccount={account} chainId={chainId} tokenBalance={tokenBalance}/> : <></>} */}
            {/* </Box> */}


            {/* <Slide direction="bottom" >
                    <Footer />
                </Slide> */}
        </>

        // </ChakraProvider>
        // <Box textAlign="center" maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
        //     <chakra.h1
        //         textAlign={'center'}
        //         fontSize={'4xl'}
        //         py={10}
        //         fontWeight={'bold'}>
        //         Bridge
        //     </chakra.h1>
        //     <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        //         <Box textAlign="center" verticalAlign={'middle'} fontSize="xl">
        //             <Grid minH="80vh" p={3}>
        //                 <ColorModeSwitcher justifySelf="flex-end" />
        //             <VStack spacing={0}>
        //                 {/* <Logo h="40vmin" pointerEvents="none" /> */}
        //                 {/* <Text>Bridge</Text> */}
        //                 <InputValueBox title='BSC' />
        //                 <InputValueBox title='ETH' />
        //                 {/* <Text>
        //                     <Code fontSize="xl">{account && account.slice(0, 7)}...{account && account.slice(-4)}</Code>
        //                 </Text> */}
        //                 {/* <Link
        //                     color="teal.500"
        //                     href="https://chakra-ui.com"
        //                     fontSize="2xl"
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                 >
        //                     Learn Chakra
        //                 </Link> */}
        //                 {/* <ConnectButton /> */}
        //                 </VStack>
        //             </Grid>
        //         </Box>
        //     </SimpleGrid>
        // </Box>
    );
}

export default Home;
