import Html5QrcodeReader from "./Html5QrcodeReader";

function Html5BarcodeReader(props) {

    return (
        <Html5QrcodeReader qrbox={{ width: 300, height: 100 }} onlybarcode={true} />
    )

}

export default Html5BarcodeReader;
