import {
  Box,
  Text,
  Flex,
  Center,
  Image,
  useColorModeValue,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';

import { TITLE } from "../config";

interface LoginProps {
    label: string;
  size: string;
  extraLabel: string;
  }
export default function Loading(props: LoginProps) {
  // console.log('withdrawls', state.withdrawls);
  const {label, size, extraLabel} = props;
  // const bgGradient = '';
  // const hoverBgGradient = '';
  const from = 'white';
  const to = 'gray.700';
  const color = useColorModeValue(from, to);
  const colorInv = useColorModeValue(to, from);
  return (
    // <Box maxW="3xl" mx={'auto'} pt={1} pb={0} px={{ base: 2, sm: 12, md: 17 }}>
      /* <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        
      </chakra.h1> */
    //   <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>
        // <Stat
        //   px={{ base: 4, md: 8 }}
        //   py={'5'}
        // //   shadow={'xl'}
        //   border={'0px solid'}
        //   borderColor={colorInv}
        //   rounded={'lg'}
        //   opacity={10}
        // //   bgGradient={bgGradient}
        // //   _hover={{
        // //     bgGradient: hoverBgGradient,
        // //   }}
        // //   color={color}
        //   >
    <Flex 
      justifyContent={'center'}
      bg={color}
              display={'flex'}
              w={'100vw'}
              h={'100vh'}
              top={0}
              left={0}
              zIndex={999}
              position={'fixed'}
      background={'rgba(255, 255, 255, 0.7)'}
      >
            {/* <Box pl={{ base: 2, md: 4 }}>
              <StatLabel fontSize={'xs'} fontWeight={'medium'} isTruncated>
                
              </StatLabel>
              <StatNumber fontSize={'3xl'} fontWeight={'medium'}>
                Loading
              </StatNumber>
            </Box> */}
            <Box
              my={'auto'}
              // bg={color}
              alignContent={'center'}
              // size={"120px"}
              >
               <CircularProgress isIndeterminate
                thickness="5px"
                capIsRound={true}
                // speed="3s"
                // emptyColor="gray.200"
                trackColor="orange"
                color={colorInv}
                size={size === '' ? 'xs' : '140px'}
              >
        
          <CircularProgressLabel fontSize={size === '' ? '2xl' : 'xs'}>
            <Center>
              <Image src={useColorModeValue('/logoShape.png', '/logoWhiteShape.png')} alt={TITLE} width={'90px'} />
            </Center>
            {label}
            </CircularProgressLabel>
              </CircularProgress>
        <Text>{extraLabel}</Text>
            </Box>
          </Flex>
        // </Stat>
    //   </SimpleGrid>
    // </Box>
  );
}