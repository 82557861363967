import React from 'react'
// import Clock from "./Clock";
// import { ReactNode } from 'react';
import {
    Box,
    Flex,
    // Button,
    useColorModeValue,
    // Stack,
    Image,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    useColorMode,
} from '@chakra-ui/react';
import { FaHome, FaMoon, FaRedoAlt, FaSun } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import { getApikey, getNameuser } from '../../utils/SessionToken';
import { HOME_URL } from '../../config';
import { useSelector } from "react-redux";

import { TITLE } from "../../config";
import Loading from '../../utils/Loading';

const Header = React.memo(props => {
    const { isOpen, onOpen } = useDisclosure();
    const history = useHistory();
    const { userData } = useSelector((state) => state.user);
    // const { selectedVoucher } = useSelector((state) => state.user);
    const { colorMode, toggleColorMode } = useColorMode()

    // const [apiKey, setApiKey] = useState('');
    // // const [nameUser, setNameUser] = useState('');

    // useEffect(() => {
    //     if (!apiKey) {
    //         getApiKeyFromStorage();
    //     }
    // }, [apiKey]);

    const handleRefreshClick = async () => {

        onOpen();
        caches.keys().then((keyList) => {
            return Promise.all(
                keyList.map((key) => {
                    return caches.delete(key);
                })
            );
        });
        setTimeout(() => {
            window.location.reload(true);
            // window.location.href = window.location.protocol + '//' + window.location.host;
        }, 1000);
    }

    // const getApiKeyFromStorage = async () => {
    //     const { apiKey } = await getApikey()
    //     setApiKey(apiKey);
    //     console.log('header getNameuser')
    //     // const nameUser = await getNameuser();
    //     // setNameUser(nameUser);
    //     // setNameUser(localStorage.nameuser);
    // };

    // const signout = () => {
    //     console.log('signout');
    //     localStorage.apiKey = ''
    //     history.push("/");
    // }

    // const signoutRef = React.useRef(null);
    // const signout = React.useCallback(
    //     async () => {
    //         console.log('signout');
    //         localStorage.apiKey = '';
    //         localStorage.nameuser = '';
    //         window.location.href = window.location.protocol + '//' + window.location.host;
    //         history.push("/");
    //     },
    //     [history]
    // );
    const handleGoHome = () => {
        // console.log('handleGoHome', HOME_URL + (selectedVoucher !== false && selectedVoucher))
        history.push(HOME_URL);
    }

    // const bgGradient = useColorModeValue('linear(to-r, yellow.400, cyan.400)', 'linear(to-r, yellow.500, cyan.500)')
    const bgGradient = useColorModeValue('linear(to-r, gray.300, gray.300)', 'linear(to-r, gray.500, gray.500)')
    const userTextColor = useColorModeValue('gray.700', 'whiteAlpha.900');
    return (
        <>
            <Box
                bgGradient={bgGradient}
                px={4}
                h='80px'
                color={useColorModeValue('gray.700', 'whiteAlpha.300')}
                // z-index='9999'
                // sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0', zIndex: 9999 }}
                >
                <Flex h='80px' alignItems={'center'} justifyContent={'space-between'}>
                    <Box w={[100]} textAlign={'center'}>
                        {/* <Link href='#' onClick={handleGoHome}> */}
                        <Image src='/logo.png' alt={TITLE} ml={'-10px'} onClick={handleGoHome} />
                        {/* {TITLE} */}
                        {/* </Link> */}
                        {/* {nameUser && <Text fontSize={'0.6em'} textColor={'black'} noOfLines={1} >{nameUser}</Text>} */}
                    </Box>
                    <Box textAlign={'center'}>
                        {userData !== undefined && <Text fontSize={{ base: '0.6em', md: '20px' }} textColor={userTextColor} >{userData.name}</Text>}
                    </Box>
                    {/* <Clock /> */}

                    <Flex alignItems={'center'}>
                        {/* <Button onClick={handleRefreshClick} mb={'3px'} mr={'5px'} variant='orange'><FaRedoAlt size={'1em'} />&nbsp;Refresh</Button> */}
                        {/* <Stack direction={'row'} spacing={5}> */}
                            {/* {nameUser && <Button onClick={handleGoHome} mb={'3px'} ><FaHome size={'1em'} /></Button>} */}
                            
                            {/* <Text fontSize={'0.5em'} textColor={'blue.800'} textAlign={'center'} >{APP_VERSION}</Text> */}
                        {/* </Stack> */}
                        {/* <Button onClick={handleRefreshClick} mb={'3px'} ml={'5px'} ><FaRedoAlt size={'1em'} /></Button> */}
                        {/* <Button
                            onClick={toggleColorMode}
                            p={'0px'}
                        > */}
                        {userData !== undefined && 
                            <>
                                {/* <Box ml={'6px'} color={userTextColor}>
                                    <FaHome onClick={handleGoHome} size={'1.4em'} />
                            </Box> */}
                            <Box ml={'6px'} color={userTextColor}>
                                <FaHome onClick={handleGoHome} size={'1.4em'} />
                            </Box>
                        </>
                        }
                        <Box ml={'6px'} mr={'-20px'} color={userTextColor}>
                            <FaRedoAlt onClick={handleRefreshClick} size={'1.1em'} />
                        </Box>
                        <Box mr={'0px'} mt={'-54px'} ml={'8px'} color={userTextColor}>
                            {colorMode === 'light' ? <FaSun onClick={toggleColorMode} size={'0.6em'} /> : <FaMoon onClick={toggleColorMode} size={'0.6em'} />}
                        </Box>
                        {/* </Button> */}
                    </Flex>
                </Flex>
            </Box>
            <Modal
                isCentered
                // onClose={handleCloseModal}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                // size={'full'}
                scrollBehavior='inside'
                
            >
                <ModalOverlay
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='20%'
                    backdropBlur='2px'
                    opacity={'30%'}
                />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    {/* <ModalCloseButton size='lg' border={'none'} background={'hidden'} width={'10px'} padding={'20px'} /> */}
                    <ModalBody width={'100%'} height='calc(100vh)'>
                        {/* <Text>Aggiorno l'applicazione</Text> */}
                        <Loading extraLabel="Aggiorno l'applicazione" size='120px' />
                        {/* <VoucherResultsApi /> */}
                    </ModalBody>

                    {/* <ModalFooter>
                            <Button colorScheme='gray' mr={3} onClick={handleCloseModal}>
                                Torna alla lista voucher
                            </Button> */}
                    {/* <Button variant='ghost'>Secondary Action</Button> */}
                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    );
});

export default Header;