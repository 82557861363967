import React, { useState } from "react";
import {
    Box,
    VStack,
    Button,
    // Tooltip,
    Text,
    Table,
    Thead,
    Tbody,
    // Tfoot,
    Tr,
    // Th,
    Td,
    Input,
    TableContainer,
    Image,
    Center,
    useColorModeValue
} from '@chakra-ui/react';
// import { useHistory } from "react-router-dom";
import { FaCamera, FaHome } from "react-icons/fa";
import * as CONFIG from '../config'
import { ButtonBox } from "./HomeButton";
import { useHistory } from "react-router-dom";
import { deleteOrphanImage, storeOrphanVoucherNote } from "../utils/ApiUtilities";
import { useSelector } from "react-redux";
import ActionButtons from "./ActionButton";

function OrphanVoucherResults(props) {
    const { apiKey } = useSelector((state) => state.user);
    const history = useHistory();
    const [ note, setNote ] = useState('')
    const { results, restoreCapture, scanImage } = props;
    const noteBorderColor = useColorModeValue('gray.500', 'gray.400');
    // console.log('Scan done', results);
    // const descFontSize = 'xs';
    // const valueFontSize = 'md';

    const handleDelete = async (idImage) => {
        // console.log('idImage', idImage)
        await deleteOrphanImage(idImage)
        restoreCapture();
        // history.push(HOME_URL);
        // history.push(VOUCHER_ORPHAN_PHOTO);
    }
    const handleStoreNote = async (id) => {
        // console.log('idImage', idImage)
        await storeOrphanVoucherNote(id, note)
        // restoreCapture();
        history.push(CONFIG.HOME_URL);
        // history.push(VOUCHER_ORPHAN_PHOTO);
    }

    const handleNote = (note) => {
        // console.log('note', note)
        setNote(note);
    }

    return (
            <>
                <VStack>
                    <Box mt={'10px'}>
                        <TableContainer
                            whiteSpace={'wrap'}
                        >
                            <Table
                            variant='unstyled'
                                size={'sm'}
                            >
                                {/* <TableCaption>Voucher valido</TableCaption> */}
                                <Thead>
                                    <Tr>
                                        <Td colSpan='2' textAlign={'center'} fontSize={'1.5em'} >
                                        {results.success ? <Text mb={'10px'} color={'green'} lineHeight={'1.5em'}>{results.message.length > 0 && results.message[0]}</Text> : 
                                        <>
                                        <Text mb={'10px'} color={'red'} lineHeight={'1.5em'}>{results.error.length > 0 && results.error[0]}</Text>
                                                <ButtonBox title={'Home'} icon={<FaHome size={'1em'} />} fontSize={'1em'} link={CONFIG.HOME_URL} paddingBottom={'15px'} />
                                        </>
                                        }
                                        </Td>
                                </Tr>
                            </Thead>
                                {results.success && results.orphanvoucher &&

                                    <Tbody>
                                        <Tr>
                                            <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>
                                                <Center>

                                                {scanImage ?
                                                    <Image src={scanImage} /> :
                                                    <Image src={CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey.apiKey + '/' + btoa(results.orphanvoucher.id)} maxHeight={'60%'} />
                                                }
                                                {/* {imgUrl = CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + results.orphanvoucher.image} */}
                                                {/* <Image src={CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey.apiKey + '/' + btoa(results.orphanvoucher.id)} maxHeight={'60%'}/> */}
                                            </Center>
                                            <Box mt={'-0px'} mb={'60px'}>
                                                <Input
                                                    placeholder="Inserisci eventuali Note"
                                                    size='md'
                                                    variant="outline"
                                                    value={note}
                                                    onChange={(event) => handleNote(event.target.value)}
                                                    // mb={'12px'}
                                                    // width={'90%'}
                                                    borderColor={noteBorderColor}
                                                    mt={'8px'}
                                                />
                                                {/* BUTTON_ORPHAN_FINALIZE */}
                                                <ActionButtons fontSize={'14px'} leftIcon={<FaHome />} variant='green' width={'48%'} onClick={() => { handleStoreNote(results.orphanvoucher.id) }} mr={'5px'} text={note !== '' ? CONFIG.BUTTON_SAVE_NOTE : CONFIG.BUTTON_ORPHAN_FINALIZE} />
                                                <ActionButtons fontSize={'14px'} leftIcon={<FaCamera />} variant='orange' width={'50%'} onClick={() => { handleDelete(results.orphanvoucher.id) }} text={CONFIG.BUTTON_ORPHAN_REPEAT} />
                                            </Box>
                                            </Td>
                                        </Tr>
                                        {/* <Tr>
                                            <Td fontSize={descFontSize}><ButtonBox title={'Home'} icon={<FaHome size={'2em'} />} link={'/'} /></Td>
                                            <Td fontSize={valueFontSize}><ButtonBox title={'Cancella'} icon={<FaTrash size={'1em'} />} link={'/'} /></Td>
                                        </Tr> */}
                                    {/* <Tr>
                                        <Td fontSize={descFontSize}>
                                            <Button leftIcon={<FaHome />} colorScheme='green' variant='solid' width={'100%'} onClick={() => { history.push(HOME_URL) }}>
                                                Concludi check-in
                                            </Button>
                                        </Td>
                                            <Td fontSize={valueFontSize}>
                                            <Button leftIcon={<FaTrash />} colorScheme='red' width={'100%'} onClick={() => { handleDelete(results.orphanvoucher.id) }}>
                                                    Cancella
                                                </Button>
                                            </Td>
                                    </Tr> */}
                                    <Tr>
                                        <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>
                                            &nbsp;
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td colSpan={'2'} textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'18px'}>
                                            &nbsp;
                                        </Td>
                                    </Tr>
                                    </Tbody>
                                }
                            
                            </Table>
                        </TableContainer>
                    </Box>
                </VStack>

            {/* <Box maxW="7xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }} mb={'100px'}>
                <ButtonBox title={'Home'} icon={''} link={'/'} />
                </Box> */}
            </>
    );

}

export default OrphanVoucherResults;
