import { ActionTypes } from "../constants/action-types";
import * as CONFIG from '../../config'

const intialState = {
  nameUser: '',
  apiKey: '',
  checkUser: true,
  // selectedAccordion: { ieri: 0, oggi: 0, domani: 0, dopodomani: 0 },
  selectedAccordion: {},
  selectedVoucher: false,
  whenList: new Date(),
  whenListDatePicker: new Date(),
  scrollPos: {},
  userData: {},
  voucherQuery: '',
  uiVersion: CONFIG.APP_VERSION
};

export const userReducer = (state = intialState, { type, payload }) => {
  // console.log(type);
  // console.log(payload);
  switch (type) {

    case ActionTypes.SET_ACCOUNT:
      return { ...state, nameUser: payload };
    case ActionTypes.SET_USER_DATA:
      // console.log('SET_USER_DATA', payload);
      // console.log(state);
      if (payload === undefined) {
        return { ...state, userData: payload };
        // return state;
      }
      payload.minShowAccordion = payload.minShowAccordion !== undefined ? payload.minShowAccordion : 30;
      payload.minGroupAccordion = payload.minGroupAccordion !== undefined ? payload.minGroupAccordion : 5;
      return { ...state, userData: payload };
      // console.log('SET_USER_DATA', sel);
      // return sel;
      // return { ...state, userData: payload };
    case ActionTypes.SET_APIKEY:
      return { ...state, apiKey: payload };
    case ActionTypes.SET_CHECKUSER:
      return { ...state, checkUser: payload };
    case ActionTypes.SET_SELECTED_ACCORDION:
      // const sel = { ...state, selectedAccordion: payload };
      // console.log('SET_SELECTED_ACCORDION', sel.selectedAccordion);
      // return sel;
      return { ...state, selectedAccordion: payload };
    case ActionTypes.SET_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: payload };
    case ActionTypes.SET_WHEN_LIST:
      // const sel = { ...state, whenList: payload };
      // console.log('SET_WHEN_LIST', sel);
      // return sel;
      return { ...state, whenList: payload };
    case ActionTypes.SET_WHEN_LIST_DATEPICKER:
      // const sel = { ...state, whenList: payload };
      // console.log('SET_WHEN_LIST', sel);
      // return sel;
      return { ...state, whenListDatePicker: payload };
    case ActionTypes.SET_SCROLL_POS:
      // console.log('SET_SCROLL_POS', payload);
      // if (payload > 0) 
      // const sel = { ...state, scrollPos: payload };
      // else return state;
      // console.log('SET_SCROLL_POS', sel.scrollPos);
      // return sel;
      return { ...state, scrollPos: payload };
    case ActionTypes.SET_SEARCH_VOUCHER_QUERY:
      return { ...state, voucherQuery: payload };

    case ActionTypes.SET_UI_VERSION:
      return { ...state, uiVersion: payload };

    default:
      return state;
  }
};

