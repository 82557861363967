import * as CONFIG from '../config'
import { getApikey, getVouchercode } from './SessionToken';



export const checkVoucherGet = async (vouchercode) => {
  const { apiKey } = await getApikey()
  // console.log('apiKey', apiKey);
  // const {conf} = await settings();
  // const url = getConf('dex_url');
  // return fetch(`${apiMiddlewareUrl}/it/API/authToken/` + token, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_ENDPOINT + '?vouchercode=' + vouchercode + '&apiKey=' + apiKey, {
    method: 'GET',
    // headers: {
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer '+token,
    //   'Content-Type': 'application/json'
    // }
  }).then(res => res.json())
}

export async function getUiVersion() {
  const res = await _getUiVersion();
  const uiVersion = res.version ? res.version : CONFIG.APP_VERSION;
  // console.log('getUiVersion', res)
  // localStorage.settings = JSON.stringify(settings);
  return uiVersion;
}


export const _getUiVersion = async () => {
  return fetch(CONFIG.API_URL + CONFIG.UI_VERSION_ENDPOINT, {
    method: 'POST'
  })
    .then(res => res.json())
}

// export const skiUsdRate = () =>
// // fetch(`${USD_SKI_RATE_API}`, {
// // fetch(`${conf.usd_ski_rate_api}`, {
// fetch(`${getConf('usd_ski_rate_api')}`, {
//     method: 'GET',
//   }).then(res => res.json())

// export const withdrawAPI = (token) => {
//   // console.log('withdrawAPI token', token);
//     return fetch(`${getConf('dex_url')}/it/API/withdraw/` + token, {
//     method: 'GET'
//   }).then(res => res.json())
// }

export const checkUser = async () => {
  // console.log('checkUser')
  const { apiKey } = await getApikey()
  // if 
  let formData = new FormData();
  formData.append('apiKey', apiKey);
  return fetch(CONFIG.API_URL + CONFIG.CHECKUSER_ENDPOINT, {
    method: 'POST',
    body: formData,
  }).then(res => res.json());

  // fetch(CONFIG.API_URL + CONFIG.CHECKUSER_ENDPOINT, {
  //   method: 'POST',
  //   body: formData,
  // }).then(res => {
  //   if (res.ok) {
  //     console.log
  //     return res.json();
  //   }
  //   throw res;
  // }).then(data => {
  //   console.log(data);
  //   return data;
  //   })
}

export const checkVoucher = async (vouchercode, fastcheckin) => {
  const { apiKey } = await getApikey()
  let formData = new FormData();
  formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  formData.append('fastcheckin', fastcheckin !== undefined ? 1 : 0);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_ENDPOINT, {
    method: 'POST',
    // headers: new Headers({
    //   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    //   'Access-Control-Allow-Origin': 'http://localhost:3000',
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer ' + token,
    //   'Content-Type': 'application/json'
    //   }),
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // body: "email="+email+"&password="+password
    // body: JSON.stringify({ email, password }),
    body: formData,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   'Accept': '*/*',
    //   // 'Authorization': 'Bearer '+token,
    //   'Content-Type': 'application/json'
    // }
  })
    .then(res => res.json())
}

export const getVoucherDetail = async (vouchercode) => {
  const { apiKey } = await getApikey()
  let formData = new FormData();
  formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_DETAIL_ENDPOINT, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

export const voucherList = async (when) => {
  const { apiKey } = await getApikey()
  let formData = new FormData();
  formData.append('when', when);
  formData.append('apiKey', apiKey);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_LIST_ENDPOINT, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

export const uploadFileVoucher = async (file, fastcheckin) => {
  // console.log('fastcheckin', fastcheckin !== undefined ? fastcheckin : 0);
  const { apiKey } = await getApikey()
  const { vouchercode } = await getVouchercode()
  const location = CONFIG.API_URL + CONFIG.VOUCHER_IMAGE_ENDPOINT;
  let formData = new FormData();
  formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  formData.append('image', file);
  formData.append('uiversion', CONFIG.APP_VERSION);
  formData.append('fastcheckin', fastcheckin !== undefined ? 1 : 0);
  const settings = {
    method: 'POST',
    body: formData,
    // mode: 'no-cors',
    // headers: {
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // }
  };
  try {
    const fetchResponse = await fetch(location, settings);
    // console.log('fetchResponse', fetchResponse);
    const data = await fetchResponse.json();
    // console.log('data', data);
    // data.fileUploaded = true;
    return data;
  } catch (e) {
    return e;
  }
}

export const uploadOrphanFileVoucher = async (file) => {
  const { apiKey } = await getApikey()
  // const { vouchercode } = await getVouchercode()
  const location = CONFIG.API_URL + CONFIG.VOUCHER_ORPHAN_IMAGE_ENDPOINT;
  let formData = new FormData();
  // formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  formData.append('image', file);
  formData.append('uiversion', CONFIG.APP_VERSION);
  const settings = {
    method: 'POST',
    body: formData
  };
  try {
    const fetchResponse = await fetch(location, settings);
    // console.log('fetchResponse', fetchResponse);
    const data = await fetchResponse.json();
    // console.log('data', data);
    data.fileUploaded = true;
    return data;
  } catch (e) {
    return e;
  }
}

export const deleteOrphanImage = async (id) => {
  // console.log('deleteOrphanImage', id);
  const { apiKey } = await getApikey()
  let formData = new FormData();
  formData.append('id', id);
  formData.append('apiKey', apiKey);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_ORPHAN_IMAGE_DELETE_ENDPOINT, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

export const deleteImage = async (id) => {
  // console.log('deleteImage', id);
  const { apiKey } = await getApikey()
  const { vouchercode } = await getVouchercode()
  let formData = new FormData();
  formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_DELETE_IMAGE_ENDPOINT, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

export const storeVoucherNote = async (note) => {
  const { apiKey } = await getApikey()
  const { vouchercode } = await getVouchercode()
  let formData = new FormData();
  formData.append('vouchercode', vouchercode);
  formData.append('apiKey', apiKey);
  formData.append('note', note);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_NOTE, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

export const storeOrphanVoucherNote = async (id, note) => {
  const { apiKey } = await getApikey()
  let formData = new FormData();
  formData.append('id', id);
  formData.append('apiKey', apiKey);
  formData.append('note', note);
  // return fetch(`${getConf('dex_url')}/it/API/authToken`, {
  return fetch(CONFIG.API_URL + CONFIG.VOUCHER_ORPHAN_NOTE, {
    method: 'POST',
    body: formData,
  })
    .then(res => res.json())
}

// export const updateWallet = (token, wallet) => {
//   // Notification('updateWallet.', 'token='+token+' - wallet ="'+wallet+'"', 'error', 1000000);
//   // console.log('API updateWallet token', token);
//   // console.log('API updateWallet wallet', wallet);
//   let formData = new FormData();
//   formData.append('token', token);
//   formData.append('wallet', wallet);
//   return fetch(`${getConf('dex_url')}/it/API/apiUpdateWallet`, {
//     method: 'POST',
//     body: formData,
//   }).then(res => res.json())
// }


