
import {
    Button
} from '@chakra-ui/react';


function ActionButtons (props) {
    const { leftIcon, rightIcon, variant, width, onClick, text, fontSize, mr, ml, mb, mt } = props;

    //<Button leftIcon={<FaHome />} variant='green' width={'48%'} onClick={() => { handleStoreNote() }} mr={'5px'}>
//<ActionButtons fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.HOME_URL) }} mb={'100px'} text={'Torna a Lista voucher'} />
    return (
        <Button
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            variant={variant ? variant : 'actionButton'}
            fontSize={fontSize ? fontSize : '22px'}
            width={width ? width : '100%'}
            onClick={onClick}
            mr={mr}
            ml={ml}
            mt={mt}
            mb={mb}
        >
            {text}
        </Button>
    )

}

export default ActionButtons;