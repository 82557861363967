import { mode } from '@chakra-ui/theme-tools'

export const styles = {
      global: (props) => ({
    "html": {
          fontFamily: 'body',
          color: mode('gray.800', 'whiteAlpha.900')(props),
          bg: mode('whiteAlpha.900', 'gray.800')(props),
          lineHeight: 'base',
    },
    button: {
      position: 'relative',
      background: '#777',
      border: '0',
      padding: '14px 42px',
      borderRadius: '8px',
      cursor: 'pointer',
      overflow: 'hidden',
      outline: 'none',
      fontWeight: '400',
      fontSize: '14px',
      color: '#fff',
      // letterSpacing: '0.2em',
      boxShadow: '4 8px 32px rgba(0, 0, 0, 0.2)',
      transition: 'all 0.2s ease',
      marginTop: '10px'
    },
    // bunttonTyny: {
    //   position: 'relative',
    //   background: '#777',
    //   border: '0',
    //   // padding: '14px 42px',
    //   borderRadius: '8px',
    //   cursor: 'pointer',
    //   overflow: 'hidden',
    //   outline: 'none',
    //   fontWeight: '400',
    //   fontSize: '14px',
    //   color: '#fff',
    //   // letterSpacing: '0.2em',
    //   boxShadow: '4 8px 32px rgba(0, 0, 0, 0.2)',
    //   transition: 'all 0.2s ease',
    //   // marginTop: '10px'
    // },
    select: {
      marginTop: '10px'
    }
      }),
}

// export default styles;