// export const API_URL = 'https://admin.venicevoucher.it/api'
// export const API_URL = 'https://demo.venicevoucher.it/api'
// export const API_URL = 'https://api.venicevoucher.it'
export const API_URL = 'https://demo.venicevoucher.it/api'
export const AUTH_ENDPOINT = '/provider/auth';
export const VOUCHER_ENDPOINT = "/provider/checkvoucher";
export const VOUCHER_DETAIL_ENDPOINT = "/provider/voucherdetail";
export const VOUCHER_IMAGE_ENDPOINT = "/provider/storeimagevoucher";
export const VOUCHER_DELETE_IMAGE_ENDPOINT = "/provider/deleteImageVoucher";
export const VOUCHER_ORPHAN_IMAGE_ENDPOINT = "/provider/storeorphanimagevoucher";
export const VOUCHER_ORPHAN_IMAGE_DELETE_ENDPOINT = "/provider/deleteorphanimagevoucher";
export const VOUCHER_ORPHAN_NOTE = "/provider/storeNoteOrphanVoucher";
export const VOUCHER_NOTE = "/provider/storeNoteVoucher";
export const VOUCHER_GETIMAGE_ENDPOINT = "/provider/getimage";
export const CHECKUSER_ENDPOINT = "/provider/checkuser";
export const VOUCHER_LIST_ENDPOINT = "/provider/voucherlist";
// export const TICKET_GETPDF_ENDPOINT = "/provider/getpdfticket";
export const TICKET_GETPDF_ENDPOINT = "/provider/getencpdfticket";
export const UI_VERSION_ENDPOINT = "/provider/uiVersion";
export const FIRST_PHASE = 1;
export const VOUCHER_PHOTO = '/provider/photovoucher';
export const VOUCHER_SCAN = '/provider/scanvoucher';
export const VOUCHER_SCAN_BAR = '/provider/scanvoucherbarcode';
export const VOUCHER_LIST = '/provider/voucherlist';
export const QRCODE_LOGIN = '/qrcode';
export const VOUCHER_ORPHAN_PHOTO = '/provider/orphanphotovoucher';
export const VOUCHER_CHECK_IN_FROM_LIST = '/provider/checkinfromlist';
export const HOME_URL = '/';
export const APP_VERSION = '2.0.4'

export const TITLE = 'Venicevoucher'
export const DATE = () => {
    return new Date().getFullYear()
}
export const COPY = '© ' + DATE()
export const FISCAL = 'Cap. Soc. 10.000,00 € I.V. - P.Iva 03489020275'

export const BUTTON_LOGIN_QRCODE = 'Login con QR-code';
export const TEXT_LOGIN_QRCODE = 'Per poter utilizzare il software è necessario effetture la scansione del qrcode ADMIN consegnato'
// export const NOTE_ORPHAN = 'ATTENZIONE: La foto risulterà orfana e dovrà poi essere associata al voucher mediante area di amministrazione';
export const NOTE_ORPHAN = 'Il "check-in solo foto" consente di registrare il cliente ma non é associato a voucher esistenti; l\'associazione sarà poi gestita dall\'amministrazione';
export const BUTTON_ORPHAN = 'Check-in solo foto';
export const BUTTON_ORPHAN_FINALIZE = 'Concludi check-in';
export const BUTTON_ORPHAN_REPEAT = 'Ripeti foto';
export const BUTTON_SCAN_HOME = ' —> Scanner <— ';
// export const BUTTON_SCAN_HOME = '—> Click to Fast Check-in <—';
export const BUTTON_NEW_SCAN = 'Fast check-in';
export const BUTTON_BACK_TO_LIST = 'Torna a lista voucher';
export const BUTTON_BACK_TO_HOME = 'Home';
export const BUTTON_DELETE_CHECKIN = 'Annulla check-in';
export const BUTTON_COMPLETE = 'Completa';
export const BUTTON_SAVE_NOTE = 'Salva note';


export const captureHeightFast = 320;
export const captureWidthFast = 240;
export const captureHeight = 426;
export const captureWidth = 320;

export const cameraVideoConstraints = {
    // minScreenshotHeight: 4096,
    // minScreenshotWidth: 2160,
    // width: 747,
    // height: 561,
    // width: { ideal: 4096 },
    // height: { ideal: 2160 },
    // forceScreenshotSourceSize: "true",
    facingMode: "environment",
    // facingMode: { exact: "environment" },
    // screenshotQuality: 1,
    // video: {
    //   width: { ideal: 4096 },
    //   height: { ideal: 2160 }
    // },
    // imageSmoothing: "false"
};

export const cameraOrphanVideoConstraints = {
    width: 747,
    height: 561,
    forceScreenshotSourceSize: "true",
    facingMode: "environment",
    screenshotQuality: 1,
};

