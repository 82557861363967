import {
  Box,
  // chakra,
  // Container,
  // Stack,
  Text,
  useColorModeValue,
  // VisuallyHidden,
  Flex,
  Link,
  // Heading,
  // Spacer,
  // Image,
  Center,
  Slide
} from '@chakra-ui/react';
// import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
// import { ReactNode } from 'react';
import * as CONFIG from '../config'

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode;
//   label: string;
//   href: string;
// }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//       rounded={'full'}
//       w={8}
//       h={8}
//       cursor={'pointer'}
//       as={'a'}
//       href={href}
//       display={'inline-flex'}
//       alignItems={'center'}
//       justifyContent={'center'}
//       transition={'background 0.3s ease'}
//       _hover={{
//         bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}>
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };
interface FooterProps {
  copy: string;
  availibility: BigInt;
  user: string;
}
export default function Footer(props: FooterProps) {

  // const bgGradient = useColorModeValue('linear(to-r, gray.300, gray.300)', 'linear(to-r, gray.500, gray.500)')
  return (
    <Slide direction="bottom" >
      <Box
        // bg={useColorModeValue('gray.50', 'gray.800')}
        bg={useColorModeValue('gray.300', 'gray.500')}
        // bgGradient={bgGradient}
        color={useColorModeValue('gray.700', 'gray.200')}
        width="100%"
        pos="relative"
        // bottom={{ base: 38, md: 42 }}
        bottom={{ base: '48px', md: 42 }}
      // height={"35px"}
      //  left="0"
      // textAlign={'center'}
      >
        <Flex
          p={{ base: 3, md: 4 }}
          w="100%"
          h={'140px'}
          direction={{ base: 'column', lg: 'column' }}
        >
          <Box textAlign={'center'}>
            <Center>
              {/* <Text fontSize={{ md: '0.7em', lg: '0.7em', xl: '0.7em', base: '0.55em' }} textColor={'blue.100'} mt={'-3px'}>{COPY} <Link href='https://www.dyncode.it' target={'_blank'}> DYNcode srl</Link> {FISCAL} */}
              <Text fontSize={{ md: '0.7em', lg: '0.7em', xl: '0.7em', base: '0.55em' }} textColor={useColorModeValue('blue.800', 'blue.200')} mt={'-3px'}>{CONFIG.COPY} <Link href='https://www.dyncode.it' target={'_blank'}> DYNcode srl</Link>{' - '}<Link href='https://www.armainformatica.it/' target={'_blank'}>Ar.Ma. Informatica Snc</Link>
                {/* <Text fontSize={'0.5em'} textColor={'blue.800'} textAlign={'center'}>{APP_VERSION} - </Text> */}
                <Text textColor={useColorModeValue('yellow.600', 'yellow.200')} fontSize={'1em'} mt={{ md: "-5px", base: "-1px" }}>
                  v. {CONFIG.APP_VERSION}
                </Text>
              </Text>
            </Center>
            {/* <Text fontSize={'0.8em'} textColor={'gray.400'}>{FISCAL}</Text> */}
          </Box>
        </Flex>
      </Box>
    </Slide>
  );
}