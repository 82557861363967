// file = Html5QrcodePlugin.jsx

import { 
    Html5QrcodeScanner, 
    Html5QrcodeScanType, 
    // Html5QrcodeSupportedFormats 
} from "html5-qrcode";
import React from 'react';
import {
    Box
} from '@chakra-ui/react';
import * as CONFIG from "./config";

const qrcodeRegionId = "html5qr-code-full-region";
// console.log('questo')

class Html5QrcodePlugin extends React.Component {
    render() {
        // console.log('Html5QrcodePlugin render')
        // return <div id={qrcodeRegionId} />;
        return <Box id={qrcodeRegionId}></Box>
    }

    componentWillUnmount() {
        // console.log('Html5QrcodePlugin componentWillUnmount')
        // TODO(mebjas): See if there is a better way to handle
        //  promise in `componentWillUnmount`.
        // console.log('this.html5QrcodeScanner.html5Qrcode.canvasElement', this.html5QrcodeScanner.html5Qrcode.canvasElement);
        // console.log('this.html5QrcodeScanner.html5Qrcode.videoElement', this.html5QrcodeScanner.html5Qrcode.videoElement);

        // Manage scanned image
        const canvas = this.html5QrcodeScanner.html5Qrcode.canvasElement;
        // const dataURL = canvas && canvas.toDataURL();
        var resizedCanvas = document.createElement("canvas");
        var resizedContext = resizedCanvas.getContext("2d");

        // const resizedHeight = 320;
        // const resizedWidth = 240;
        resizedCanvas.height = CONFIG.captureHeightFast;
        resizedCanvas.width = CONFIG.captureWidthFast;

        resizedContext.drawImage(canvas, 0, 0, CONFIG.captureWidthFast, CONFIG.captureHeightFast);
        var resizedImg = resizedCanvas.toDataURL();
        if (typeof (this.props.setImage) === 'function') {
            this.props.setImage(resizedImg);
        }

        //https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
        // var resizedCanvas = document.createElement("canvas");
        // var resizedContext = resizedCanvas.getContext("2d");

        // resizedCanvas.height = "100";
        // resizedCanvas.width = "200";

        // var canvas = document.getElementById("original-canvas");

        // resizedContext.drawImage(canvas, 0, 0, 200, 100);
        // var myResizedData = resizedCanvas.toDataURL();
        
        this.html5QrcodeScanner.clear().catch(error => {
            console.error("Failed to clear html5QrcodeScanner. ", error);
        });
    }
    
    // captureFrame() {
    //     const canvas = document.getElementById('canvas');
    //     console.log('canvas', canvas);
    //     const video = document.getElementById('video');
    //     console.log('video', video);
    //     canvas.width = video.videoWidth;
    //     canvas.height = video.videoHeight;
    //     canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight); // for drawing the video element on the canvas
    //     /** Code to merge image **/
    //     const playImage = new Image();
    //     playImage.src = 'path to image asset';
    //     playImage.onload = () => {
    //         const startX = (video.videoWidth / 2) - (playImage.width / 2);
    //         const startY = (video.videoHeight / 2) - (playImage.height / 2);
    //         canvas.getContext('2d').drawImage(playImage, startX, startY, playImage.width, playImage.height);
    //         canvas.toBlob() = (blob) => {
    //             // Canvas element gives a callback to listen to the event after blob is prepared from canvas
    //             const img = new Image();
    //             img.src = window.URL.createObjectUrl(blob); // window object with static function of URL class that can be used to get URL from blob
    //         };
    //     };
    // }

    // composedAction(decodedText, decodedResult) {
    //     const canvas = this.html5QrcodeScanner.html5Qrcode.canvasElement;
    //     const dataURL = canvas.toDataURL();
    //     this.props.setImage(dataURL);
    //     this.props.qrCodeSuccessCallback(decodedText, decodedResult);
    // }

    componentDidMount() {
        try {
            // console.log('Html5QrcodePlugin componentDidMount')
        // const formatsToSupport = [
        //     // Html5QrcodeSupportedFormats.CODABAR,
        //     // Html5QrcodeSupportedFormats.CODE_39,
        //     Html5QrcodeSupportedFormats.CODE_93,
        //     // Html5QrcodeSupportedFormats.UPC_E,
        //     // Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
        // ];

        // Creates the configuration object for Html5QrcodeScanner.
        function createConfig(props) {
            var config = {};
            config.fps = 30;
            if (props.fps !== undefined) {
                config.fps = props.fps;
            }
            // config.qrbox = 250;
            if (props.qrbox !== undefined) {
                config.qrbox = props.qrbox;
            }
            // if (props.aspectRatio) {
            //     config.aspectRatio = props.aspectRatio;
            // }
            // if (props.disableFlip !== undefined) {
            //     config.disableFlip = props.disableFlip;
            // }
            // if (props.onlybarcode === true) {
            //     config.formatsToSupport = formatsToSupport;
            // }
            // config.videoConstraints = { facingMode: { exact: "environment" } };
            config.videoConstraints = { facingMode: "environment" }
            // config.videoConstraints = CONFIG.cameraOrphanVideoConstraints;
            // config.videoConstraints = { exact: "environment" }
            
            config.supportedScanTypes = [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
            config.rememberLastUsedCamera = true;
            // console.log('props.fullqrcode', props.fullqrcode);
            return config;
        }

        var config = createConfig(this.props);
        var verbose = this.props.verbose === true;

        // Suceess callback is required.
        if (!(this.props.qrCodeSuccessCallback)) {
            throw new Error("qrCodeSuccessCallback is required callback.");
        }

        this.html5QrcodeScanner = new Html5QrcodeScanner(
            qrcodeRegionId, config, verbose);
        // console.log('this.html5QrcodeScanner.id', this.html5QrcodeScanner);
        this.html5QrcodeScanner.render(
            this.props.qrCodeSuccessCallback,
            this.props.qrCodeErrorCallback);
        } catch (error) {
            console.error("Failed to mount html5QrcodeScanner. ", error);
        };
    }
};

export default Html5QrcodePlugin;