export const ActionTypes = {
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_USER_DATA: "SET_USER_DATA",
  SET_APIKEY: "SET_APIKEY",
  SET_CHECKUSER: "SET_CHECKUSER",
  SET_SELECTED_ACCORDION: "SET_SELECTED_ACCORDION",
  SET_SELECTED_VOUCHER: "SET_SELECTED_VOUCHER",
  SET_WHEN_LIST: "SET_WHEN_LIST",
  SET_WHEN_LIST_DATEPICKER: "SET_WHEN_LIST_DATEPICKER",
  SET_SCROLL_POS: "SET_SCROLL_POS",
  SET_SEARCH_VOUCHER_QUERY: "SET_SEARCH_VOUCHER_QUERY",
  SET_UI_VERSION: "SET_UI_VERSION"
};
