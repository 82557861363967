
import React from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import {
  Box,
  VStack,
  Button,
  Center,
  Text,
  TableContainer,
  Thead,
  Tr,
  Td,
  Table
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import { uploadOrphanFileVoucher } from "../utils/ApiUtilities";
import OrphanVoucherResults from "./OrphanVoucherResults";

import { FaCamera } from "react-icons/fa";
import Loading from "../utils/Loading";
import * as CONFIG from "../config";
import ActionButtons from "./ActionButton";
// import axios from "axios";
// import { getApikey } from "../utils/SessionToken";

function CaptureOrphanPhoto() {
  const history = useHistory();

  // const [aquired, setAquired] = useState(false);
  const [elaborated, setElaborated] = useState(false);
  const [results, setResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scanImage, setScanImage] = useState(null);

  const videoConstraints = CONFIG.cameraOrphanVideoConstraints;
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    async () => {
      setLoading(true);
      // setAquired(true);
      // const imageSrc = webcamRef.current.getScreenshot({ width: 561, height: 747 });
      const imageSrc = webcamRef.current.getScreenshot({ width: 480, height: 640 });
      // const imageSrc = webcamRef.current.getScreenshot({ width: 320, height: 426 });
      setScanImage(imageSrc);
      // const imageSrc = webcamRef.current.getScreenshot();
      // console.log('imageSrc', imageSrc);
      // uploadFileVoucher(imageSrc);
      const res = await uploadOrphanFileVoucher(imageSrc);
      // console.log('res', res);
      setResults(res);
      setLoading(res.success);
      
      //Diversificare se ho riscontrato il voucher dalla foto
      //se riscontrato trovo il voucher nei risultati
      localStorage.vouchercode = undefined;
      setElaborated(true);
      // setTimeout(function () {
      //   history.push("/");
      // }, 1500);
      // history.push("/");
    },
    [webcamRef]
  );

  const restoreCapture = () => {
    setElaborated(false);
    // setAquired(false);
    setResults(false);
    setLoading(false);
  }

  // const handlePushBarcode = () => {
  //   history.push("/barcode");
  // }

  // const handlePushQrcode = () => {
  //   history.push("/qrcode");
  // }
  return !loading ? (
    <>
      <VStack>
        <Box mt={'10px'} mb={'100px'}>
          <TableContainer
            whiteSpace={'wrap'}
          >
            <Table
              // variant='striped'
              size={'sm'}
            >
              {/* <TableCaption>Voucher valido</TableCaption> */}
              <Thead>
                <Tr>
                  <Td colSpan='2' textAlign={'center'} fontSize={{ base: '18px', md: '22px' }}>
          {results ? results.success ? <Text color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} >{results.message.length > 0 && results.message[0]}</Text> : <Text mb={'10px'} color={'red'} lineHeight={'1.5em'} fontWeight={'bold'}>{results.error[0]}</Text> : <></>}
          {/* {devices.map((device, key) => (
            <div>
              <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} />
              {device.label || `Device ${key + 1}`}
            </div>

          ))} */}
          {/* <Center> */}
          <Box>
          <Webcam
            audio={false}
            // height={320}
            ref={webcamRef}
            screenshotFormat="image/png"
            // width={1280}
            videoConstraints={videoConstraints}
            style={{ width: "100%", objectPosition: "center" }}
            />
            {/* </Center> */}
        </Box>
        <Box mt={'-45px'} pb={'80px'}>
                      <ActionButtons width='80%' leftIcon={<FaCamera />} onClick={capture} mt={'-0px'} mb={'15px'} variant='orange' size='md' text={CONFIG.BUTTON_ORPHAN} />
          {/* <Button
          onClick={capture}
          mt={'-0px'}
            mb={'15px'}
            // colorScheme='yellow'
            variant='orange'
            size='md'
            // variant='solid'
            leftIcon={<FaCamera />}
            // fontSize={'1em'}
          >
                        {CONFIG.BUTTON_ORPHAN}
          </Button> */}
                      <Text fontSize={'18px'} lineHeight={'1.5em'} color={'red'} >{CONFIG.NOTE_ORPHAN}</Text>
                      <ActionButtons onClick={() => { history.push(CONFIG.HOME_URL) }} mb={'100px'} text={CONFIG.BUTTON_BACK_TO_LIST} />
                      {/* <Button fontSize={'22px'} width={'100%'} onClick={() => { history.push(CONFIG.HOME_URL) }} mb={'100px'}>{'Torna a Lista voucher'}</Button> */}
        </Box>
              </Td>
            </Tr>
          </Thead>
        {/* <Text>{CONFIG.NOTE_ORPHAN}</Text> */}
        {/* <Box>
          <Button onClick={handlePushBarcode}>
            Barcode
          </Button>
          <Button onClick={handlePushQrcode}>
            Qrcode
          </Button>
        </Box> */}
        </Table>
        </TableContainer>
        </Box>
      </VStack>
    </>
  ) : (
    //Diversificare verso VoucherResults se ho riscontrato il voucher dalla foto
      elaborated === true ? <OrphanVoucherResults results={results} elaborated={elaborated} restoreCapture={restoreCapture} scanImage={scanImage} /> :
        <>
          <Text mb={'10px'} mt={'15px'} color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} textTransform={'uppercase'} fontSize={'2xl'}>Upload immagine in corso</Text>
          <Loading label='Loading...' size='120px' />
        </>
  );

}

export default CaptureOrphanPhoto;
