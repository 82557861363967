import { ActionTypes } from "../constants/action-types";

export const setAccount = (accountName) => {
  return {
    type: ActionTypes.SET_ACCOUNT,
    payload: accountName,
  };
};

export const setApikey = (apiKey) => {
  return {
    type: ActionTypes.SET_APIKEY,
    payload: apiKey,
  };
};

export const setCheckuser = (payload) => {
  return {
    type: ActionTypes.SET_CHECKUSER,
    payload: payload,
  };
};

export const setuserData = (payload) => {
  return {
    type: ActionTypes.SET_USER_DATA,
    payload: payload,
  };
};

export const setSelectedaccordion = (payload) => {
  // console.log('setSelectedaccordion', payload)
  return {
    type: ActionTypes.SET_SELECTED_ACCORDION,
    payload: payload,
  };
};

export const setSelectedvoucher = (payload) => {
  // console.log('setSelectedvoucher', payload)
  return {
    type: ActionTypes.SET_SELECTED_VOUCHER,
    payload: payload,
  };
};

export const setWhenList = (payload) => {
  // console.log('setWhenList', payload)
  return {
    type: ActionTypes.SET_WHEN_LIST,
    payload: payload,
  };
};

export const setScrollPos = (payload) => {
  // if (payload > 0) {
    // console.log('setScrollPos', payload)
    return {
      type: ActionTypes.SET_SCROLL_POS,
      payload: payload,
    };
  // } else {
  //   console.log('setScrollPos ZERO; NOT SET', payload)
  // }
};


export const setSearchVoucherQuery = (payload) => {
  // console.log('setWhenList', payload)
  return {
    type: ActionTypes.SET_SEARCH_VOUCHER_QUERY,
    payload: payload,
  };
};


export const setWhenListDatepicker = (payload) => {
  // console.log('setWhenList', payload)
  return {
    type: ActionTypes.SET_WHEN_LIST_DATEPICKER,
    payload: payload,
  };
};

export const setUiVersion = (payload) => {
  // console.log('setWhenList', payload)
  return {
    type: ActionTypes.SET_UI_VERSION,
    payload: payload,
  };
};


